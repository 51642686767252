import { environment } from './../../../../environments/enviroment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IGateway,
  IGatewayData,
  IGatewayEditRequest,
  IGatewayAddRequest,
  IGatewayResponse,
} from '../interfaces/gateways';

@Injectable({
  providedIn: 'root',
})
export class GatewaysService {
  // used to store object from all gateways to use in edit comp
  public editData!: IGatewayData;

  private userToken = sessionStorage.getItem('adminUserToken');
  private _HttpClient = inject(HttpClient);

  // get all accounts || gateways
  getAllGateways(): Observable<IGateway> {
    return this._HttpClient.get<IGateway>(
      `${environment.apiUrl}/v1/account?page=${1}&limit=${1000}`
    );
  }

  // get all Gateways With Pagination
  getGatewaysWithPagination(
    page: number,
    limit: number,
    name?: string,
    status?: boolean
  ): Observable<IGateway[]> {
    console.log('status: ', status);
    let optionalParams = [];
    if (name) optionalParams.push(`&name=${name}`);
    if (status || status == false) optionalParams.push(`&isActive=${status}`);
    const url = `${
      environment.apiUrl
    }/v1/account?page=${page}&limit=${limit}${optionalParams
      .join('')
      .split(',')
      .toString()}`;
    return this._HttpClient.get<IGateway[]>(url);
  }

  // create Gateway
  addGateway(formData: IGatewayAddRequest): Observable<IGatewayResponse> {
    return this._HttpClient.post<IGatewayResponse>(
      `${environment.apiUrl}/v1/account`,
      formData
    );
  }

  // update gateway Account
  updateGateway(formData: IGatewayEditRequest): Observable<IGatewayResponse> {
    return this._HttpClient.put<IGatewayResponse>(
      `${environment.apiUrl}/v1/account/${formData._id}`,
      formData
    );
  }

  // delete Gateway
  deleteGatewayAccount(id: string): Observable<IGatewayResponse> {
    return this._HttpClient.delete<IGatewayResponse>(
      `${environment.apiUrl}/v1/account/${id}`
    );
  }

  // get single gateway using id
  getGatewaysInfoById(id: string): Observable<IGatewayResponse> {
    return this._HttpClient.get<IGatewayResponse>(
      `${environment.apiUrl}/v1/account/${id}`
    );
  }

  // activate gateway account
  activateGatewayById(id: string): Observable<IGatewayResponse> {
    return this._HttpClient.patch<IGatewayResponse>(
      `${environment.apiUrl}/v1/account/activate/${id}`,
      {}
    );
  }

  // deactivate gateway account
  deactivateGatewayById(id: string): Observable<IGatewayResponse> {
    return this._HttpClient.patch<IGatewayResponse>(
      `${environment.apiUrl}/v1/account/deactivate/${id}`,
      {}
    );
  }
}
